import { Tooltip } from 'antd';
import React, { useState, useEffect } from 'react';
import { Delete } from '@material-ui/icons';
import { TextField, IconButton } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/styles';
import { v4 as uuidv4 } from 'uuid';
import Modal from '../../MaterialUIModal';
import FolderDeleteConfirmationModal from './FolderDeleteConfirmationModal';
import { Row, Column } from './SavedRowsModal.styled';
import { saveFolder } from '../savedRows/api';
import { useToast } from '../../../redux/action';

const useStyles = makeStyles(() => ({
  selectField: {
    color: '#212121',
    '&:focus': {
      backgroundColor: 'transparent',
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      '&.Mui-focused fieldset': {
        borderColor: '#007ade',
        borderWidth: '2px',
      },
    },
    '& .MuiInputBase-input': {
      color: '#212121',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
  },
}));

const SavedRowsModal = ({
  resolve,
  args,
  setDynamicRowsConfiguration,
  dynamicRowsConfiguration,
  orgRows,
}) => {
  const { row, onHide, isNew } = args;
  const classes = useStyles();
  useEffect(() => {
    if (isNew) {
      row.metadata = {};
    }
  }, []);
  const { errorToast, successToast } = useToast();

  const [rowName, setRowName] = useState('');
  const [folderName, setFolderName] = useState('');
  const [tempFolderName, setTempFolderName] = useState('');
  const [folderId, setFolderId] = useState('');
  const [
    isRowCategoryDeleteConfirmationModalVisible,
    setRowCategoryDeleteConfirmationModalVisibility,
  ] = useState(false);
  const [folderNames, setFolderNames] = useState(
    dynamicRowsConfiguration.externalContentURLs || []
  );
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const updateRowName = event => setRowName(event.target.value);

  const isFolderIdValid = () => {
    if (
      !row?.metadata?.folderId ||
      !dynamicRowsConfiguration?.externalContentURLs
    ) {
      return false;
    }

    return dynamicRowsConfiguration.externalContentURLs.some(
      folder => folder.handle === row.metadata.folderId
    );
  };

  useEffect(() => {
    setFolderNames(dynamicRowsConfiguration.externalContentURLs);
    const isValid = isFolderIdValid();
    if (isValid) {
      setRowName(row.metadata.name);
      setFolderId(row?.metadata?.folderId);
      setFolderName(row?.metadata?.folder);
    }
  }, [dynamicRowsConfiguration]);

  const handleSave = async () => {
    if (rowName && folderName && folderId) {
      resolve({
        name: rowName,
        folder: folderName,
        folderId: folderId,
      });
      onHide();
      if (isNew) {
        successToast('Successfully saved a new row.');
      } else {
        successToast('Saved row successfully updated.');
      }
    } else if (rowName && tempFolderName) {
      const { folderId, folderName } = await createFolderIfItDoesNotExist(
        tempFolderName
      );
      resolve({
        name: rowName,
        folder: folderName,
        folderId: folderId,
      });
      onHide();
      successToast('Successfully saved a new row.');
    }
  };

  const handleCancel = () => {
    resolve('Save cancelled');
    onHide();
  };

  const handleFolderChange = value => {
    try {
      const { name, id } = JSON.parse(value);
      setFolderName(name);
      setFolderId(id);
    } catch (error) {
      console.error('Error parsing folder value:', error);
    }
  };

  const createNewFolder = async newFolderName => {
    try {
      setFolderName(newFolderName);
      const folderId = uuidv4();

      setFolderNames([
        ...folderNames,
        { name: newFolderName, handle: folderId },
      ]);

      setFolderId(folderId);
      await saveFolder({ name: newFolderName, id: folderId });

      handleFolderChange(JSON.stringify({ name: newFolderName, id: folderId }));
      setDynamicRowsConfiguration(prevConfig => ({
        ...prevConfig,
        externalContentURLs: [
          ...prevConfig.externalContentURLs,
          {
            name: newFolderName,
            id: folderId,
            handle: folderId,
            isLocal: true,
            behaviors: { canEdit: true, canDelete: true },
          },
        ],
      }));
      successToast('Successfully created a new folder.');
      return { folderId, folderName: newFolderName };
    } catch (e) {
      console.error('Error in handleSave:', e);
      errorToast('Encountered an error while saving a folder.');
    }
  };

  const createFolderIfItDoesNotExist = async inputValue => {
    const existingCategory = folderNames.find(
      cat => cat.name.toLowerCase() === inputValue.toLowerCase().trim()
    );
    if (!existingCategory && inputValue.trim() !== '') {
      return createNewFolder(inputValue);
    }
  };

  const renderModal = () => {
    if (isRowCategoryDeleteConfirmationModalVisible) {
      return (
        <FolderDeleteConfirmationModal
          categoryToDelete={categoryToDelete}
          setDynamicRowsConfiguration={setDynamicRowsConfiguration}
          setRowCategoryDeleteConfirmationModalVisibility={
            setRowCategoryDeleteConfirmationModalVisibility
          }
          setFolderName={setFolderName}
        />
      );
    } else {
      return (
        <Modal
          className="templatemodal save-as-modal"
          title="New Saved Row"
          subtitle="Save and reuse your favorite rows. Give it a name, then keep it organized in a folder."
          onClose={handleCancel}
          primaryButton={{
            label: 'Save',
            onClick: handleSave,
            isDisabled: !rowName || !(folderName || tempFolderName),
          }}
        >
          <Column>
            <TextField
              label="Name"
              variant="outlined"
              size="small"
              value={rowName}
              onChange={updateRowName}
              fullWidth
              className={classes.textField}
            />
            <Autocomplete
              fullWidth
              size="small"
              className={classes.textField}
              options={folderNames}
              freeSolo={true}
              getOptionLabel={option => option.name}
              value={folderName ? { name: folderName, id: folderId } : null}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleFolderChange(
                    JSON.stringify({
                      name: newValue.name,
                      id: newValue.handle,
                    })
                  );
                } else {
                  setTempFolderName('');
                  setFolderName('');
                  setFolderId('');
                }
              }}
              onInputChange={(event, newInputValue) => {
                setTempFolderName(newInputValue ? newInputValue.trim() : null);
              }}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  const inputValue = event.target.value;
                  createFolderIfItDoesNotExist(inputValue);
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Folder"
                  variant="outlined"
                  className={classes.selectField}
                />
              )}
              renderOption={option => {
                let isDisabled = false;
                if (orgRows[option.handle]?.length) {
                  isDisabled = true;
                }
                return (
                  <Row>
                    {option.name}
                    <Tooltip title="Permanently deletes this folder. Folder must be empty of all saved rows.">
                      <span>
                        <IconButton
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (isDisabled) {
                              errorToast(
                                "Can't delete folder.  Folder must be empty of all saved rows."
                              );
                            } else {
                              setCategoryToDelete(option);
                              setRowCategoryDeleteConfirmationModalVisibility(
                                true
                              );
                            }
                          }}
                        >
                          <Delete
                            fontSize="small"
                            style={{
                              color: isDisabled
                                ? 'rgba(0, 0, 0, 0.26)'
                                : 'grey',
                            }}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Row>
                );
              }}
            />
          </Column>
        </Modal>
      );
    }
  };

  return <Column>{renderModal()}</Column>;
};

export default SavedRowsModal;
